import React from "react"
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import { graphql } from "gatsby"

import Grid from "../components/grid/Grid"
import Layout from "../components/Layout"
import CTA from "../components/CTA"
import SEO from "../components/SEO"

const TeamMember = ({
  className,
  imageData = null,
  name = "",
  role = "",
  content = null,
}) => (
  <Grid className="gap-y-10">
    <div className="col-span-full md:justify-self-end  lg:col-start-2 lg:col-span-4 max-w-full  sm:max-w-lg md:max-w-sm lg:max-w-full ">
      <GatsbyImage
        image={imageData}
        imgClassName="rounded-2xl"
        alt={name}
        title={name}
      />
    </div>
    <div className="col-span-full md:col-end-10  lg:col-start-7 lg:col-end-12 mt-5 row-start-1 ">
      <h2 className="mb-5">{name}</h2>
      <h4 className="mb-4">{role}</h4>
      <div className="grid gap-y-5">{content}</div>
    </div>
  </Grid>
)

const Team = ({ data }) => {
  const alexisContent = {
    name: "Alexis Officer",
    role: "Owner + Physiotherapist",
    content: (
      <>
        <p>
          Growing up as a competitive rhythmic gymnast, Alexis discovered the
          joy of movement at an early age. She’d spend hours in the gym immersed
          in the flow of putting her body in motion. She also experienced the
          reality of injuries and learned how failing to take care of your body
          can drastically reduce your quality of life.
        </p>
        <p>
          This curiosity about the science of movement led her to pursue a
          Bachelor of Science in Human Kinetics from the University of Guelph
          and a Master of Science in Physiotherapy from McMaster University.
        </p>
        <p>
          In 2018, Alexis decided to start her own practice, Art + Science
          Physio and Pilates, in Smiths Falls.
        </p>
        <p>
          The clinic provides an accessible, calming, and safe space where you
          can connect with your therapists and, most importantly, feel heard and
          supported.
        </p>
        <p>
          With a focus on chronic and persistent pain, Alexis has published
          several research papers about the physical functioning of those with
          chronic health conditions. With more than 14 years of clinical
          experience - including training in Stott Pilates Matwork, Reformer and
          Cadillac - Alexis supports clients of all fitness levels to improve
          the quality of their movement and develop a deeper connection between
          their mind and body.
        </p>
        <p>
          Alexis calls Smiths Falls home and is proud to be a part of the local
          business community.
        </p>
      </>
    ),
  }

  const pilatesContent = {
    name: "Danielle Doucet",
    role: "Pilates Instructor",
    image: data.danielleImage,
    content: (
      <>
        <p>
          Danielle loves to move. In fact, she’s so energetic, she finds it hard
          to sit still, which is probably what makes her such an effective
          clinical Pilates instructor. With over a decade of experience in the
          field, Danielle is a fully-certified STOTT Pilates Instructor, a
          specialist in Pilates-based education for Neurological Conditions and
          a certified{" "}
          <a
            href="https://www.pilates.com/store/oov/"
            target="_blank"
            rel="noopener noreferrer"
            className="inlineLink"
          >
            OOV Instructor
          </a>
          .
        </p>
        <p>
          She’s committed to uncovering her client’s unique issues, imbalances,
          habits and limitations. After addressing their specific challenges,
          she helps clients find ways to improve their movement patterns and
          gain a better awareness of their body.
        </p>
        <p>
          Danielle believes strongly in treating everyone with kindness and
          compassion. After working with clients who live with chronic and acute
          pain - and experiencing chronic pain herself - she knows the worst
          injuries in life are often invisible.
        </p>
        <p>
          She has a son and a daughter and currently lives in Crystal beach with
          her dog, Stella, a beautiful Belgian Tervuren. When she’s not teaching
          Pilates, you can find her downhill or cross country skiing,
          paddleboarding, kayaking or whipping up a delicious meal in the
          kitchen (with wine in hand).
        </p>
      </>
    ),
  }

  const rmtContent = {
    name: "Leia Antunes",
    role: "Registered Massage Therapist",
    image: data.leiaImage,
    content: (
      <>
        <p>
          Helping people feel better was a calling for Leia. For more than 17
          years, she’s done just that by providing safe and effective massage
          therapy treatments for her clients. She thrives on meeting new people,
          building relationships and learning something new every day.
        </p>
        <p>
          Leia received her Registered Massage Therapy Diploma from ICT Kikkawa
          College in 2004 and a Pilates Instructor Mat Work Certification from
          Body Harmonics in 2005. She has worked in hotels, spas and
          chiropractic clinics, and owned and operated her own massage practice
          for several years.
        </p>
        <p>
          Today, she enjoys working with a team of like-minded, passionate
          practitioners at Art + Science.
        </p>
        <p>
          When she’s not in the clinic, you can find her directing the Redeemer
          Dance Academy (she received her Certificate of Completion of the Royal
          Winnipeg Ballet School Professional Division Program in 2001), while
          teaching dance and Pilates classes. She also enjoys cooking, eating,
          walking and talking (sometimes all at once).
        </p>
        <p>
          Leia currently lives in Smiths Falls with her husband, three children,
          two cats and their new pup Maple.
        </p>
      </>
    ),
  }

  return (
    <Layout>
      <SEO title="Our Team" />
      <div className="grid gap-y-10 lg:gap-y-28">
        <Grid>
          <h1 className="col-span-full lg:col-start-2 lg:col-span-7 ">
            Our therapists are movement specialists who truly care.
          </h1>
        </Grid>
        <TeamMember
          imageData={getImage(data.alexisImage)}
          name={alexisContent.name}
          role={alexisContent.role}
          content={alexisContent.content}
        />
        <Grid>
          <figure className=" col-span-full md:col-start-2 sm:col-span-5 md:col-span-7 my-10 sm:my-0 ">
            <blockquote className="text-2xl font-light  leading-10">
              "Art + Science Physio and Pilates is focused on building trusted,
              long-term relationships with our clients. We want to help you
              improve your mobility for the long term. Rather than just ‘fixing
              an injury,’ our team empowers you with the tools you need to
              improve your movement from head to toe."
            </blockquote>
            <figcaption className="font-semibold mt-3">
              Alexis Officer
            </figcaption>
          </figure>
        </Grid>
        <TeamMember
          imageData={getImage(rmtContent.image)}
          name={rmtContent.name}
          role={rmtContent.role}
          content={rmtContent.content}
        />
        {/* <TeamMember
          imageData={getImage(pilatesContent.image)}
          name={pilatesContent.name}
          role={pilatesContent.role}
          content={pilatesContent.content}
        /> */}
      </div>
      <Grid className="mt-20">
        <CTA />
      </Grid>
    </Layout>
  )
}

export const query = graphql`
  query TeamQuery {
    alexisImage: file(relativePath: { eq: "team/Alexis2022.jpg" }) {
      childImageSharp {
        gatsbyImageData(
          placeholder: BLURRED
          quality: 75
          width: 500
          transformOptions: { cropFocus: NORTH }
        )
      }
    }
    danielleImage: file(relativePath: { eq: "team/Danielle2022.jpg" }) {
      childImageSharp {
        gatsbyImageData(
          placeholder: BLURRED
          quality: 75
          width: 500
          transformOptions: { cropFocus: NORTH }
        )
      }
    }
    leiaImage: file(relativePath: { eq: "team/Leia2022.jpg" }) {
      childImageSharp {
        gatsbyImageData(
          placeholder: BLURRED
          quality: 75
          width: 500
          transformOptions: { cropFocus: NORTH }
        )
      }
    }
  }
`

export default Team
